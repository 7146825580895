import React from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';

const Love = () => (
  <Layout>
    <Sidebar showTabs="ramadan" />

    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="top"
    >
      <ol>
        <h5>GRIEF, STRESS, DEPRESSION, ANXIETY </h5>
        <div>
          <ul>
            <p />
            <li>
              <Link to="/grief-zikr/">
                All Zikr <span className={Classes.blink}>[5/13/2022]</span>
              </Link>
            </li>
          </ul>
          <p />
          <h4>References</h4>
          <ul>
            <li>
              <a
                href="https://myislamnet.medium.com/grief-and-how-to-overcome-it-90389e43505f"
                target="blank"
              >
                Grief and How to Overcome it{' '}
              </a>
            </li>
            <li>
              <a
                href="https://myislamnet.medium.com/the-power-of-gratitude-in-reaching-happiness-and-treating-stress-anxiety-and-depression-d5c8a38a3e05"
                target="blank"
              >
                Article: The Power of Gratitude in Reaching Happiness and
                Treating Stress, Anxiety, and Depression{' '}
              </a>
            </li>
            <li>
              <a href="https://youtu.be/Y1m8V-CeQ_c" target="blank">
                YouTube: The Power of Gratitude in Reaching Happiness and
                Treating Stress, Anxiety, and Depression{' '}
              </a>
            </li>
            <li>
              <Link to="#grief">
                The State of Grief - منزلة الحزن{' '}
                <span className={Classes.blink}>[12/13/2020]</span>
              </Link>
            </li>
            <li>
              <Link to="#help">Weapon: Duaa: Ask Allah For Help</Link>
            </li>
            <li>
              <a href="https://www.amazon.com/dp/B00FQN9T80/ref=cm_sw_em_r_mt_dp_AZY5P3BF0VB8JP8R6DR9">
                Book: Healing with the Medicine of the Prophet (PBUH) IBn
                ElQayeem
              </a>
            </li>
            <li>
              <a href="https://youtu.be/mlXxfExscOQ">
                YouTub - : خفف همك وغمك وحزنك وكربك بهذه الطرق الروحية"مواجهة
                الاكتئاب وعدم الرغبة في الحياة - عمرو خالد
              </a>
            </li>
            <li>
              <a href="https://youtube.com/playlist?list=PLhbs8A5De9zSVObgh99rhe7FDHdw69Ua2">
                حياة الإحسان 2022 - الحلقات كاملة{' '}
              </a>
            </li>
            <li>
              <a href="https://sunnah.com/">Hadith search</a>
            </li>
            <li>
              <a href="https://www.islamicity.org//">Quran search</a>
            </li>
          </ul>
        </div>
      </ol>
    </section>
    <section></section>
  </Layout>
);

export default Love;
